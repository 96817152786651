import React from 'react';
import './App.css';
import { isMobile, playSound } from './utils.js';
import hornSound from './sounds/Air-Horn-SoundBible.com-964603082.mp3';
import tickSound from './sounds/Tick-DeepFrozenApps-397275646.mp3';
import fireSound from './sounds/Decrement.m4a';

class Team extends React.Component {
  constructor(props) {
    super(props);
    let count;
    if (window.localStorage.getItem(props.color)) {
      count = parseInt(window.localStorage.getItem(props.color));
    } else {
      count = 0;
    }
    this.state = {
      count: count,
    };
  }
  handleIncrement = () => {
    playSound(tickSound);
    window.localStorage.setItem(this.props.color, this.state.count + 1);
    this.setState((prevState, { count }) => ({
      count: prevState.count + 1,
    }));
  };
  handleDecrement = () => {
    playSound(fireSound);
    window.localStorage.setItem(this.props.color, this.state.count - 1);
    this.setState((prevState, { count }) => ({
      count: prevState.count - 1,
    }));
  };
  handleReset = () => {
    window.localStorage.removeItem(this.props.color);
    this.setState((prevState, { count }) => ({
      count: 0,
    }));
  };
  render() {
    return (
      <div className={'center aligned ' + this.props.color + ' column'}>
        <div className={'ui vertically divided grid'}>
          <div
            className={'one column row'}
            onClick={this.handleIncrement}>
            <div className={'column'}>
              <div className={'ui tiny inverted icon header'}>
                <i className={'chevron up icon'} />
                <div
                  className={'content noselect'}
                  style= {{ fontSize: '180%' }}>
                  {this.state.count}
                </div>
              </div>
            </div>
          </div>
          <div className={'two column divided row'}>
            <div
              className={'column'}
              onClick={this.state.count > 0 ? this.handleDecrement : undefined }>
              <i className={(this.state.count > 0 ? '' : 'disabled' ) + ' chevron down icon'} />
            </div>
            <div
              className={'column'}
              onClick={this.state.count > 0 ? this.handleReset : undefined }>
              <i className={(this.state.count > 0 ? '' : 'disabled' ) + ' trash icon'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secondsRemaining: props.startTime || 45,
      startTime: props.startTime || 45,
      isOn: false,
      timerColor: 'black',
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }

  startTimer() {
    // mute and play the sound so we can play it again
    // during the timeout
    this.stopSound.muted = true;
    this.stopSound.play();
    this.setState({ isOn: true });
    this.timer = setInterval(() => {
      let nextSecondsRemaining = this.state.secondsRemaining - 0.1;
      if (nextSecondsRemaining <= 0) {
        this.stopSound.muted = false;
        this.stopSound.play();
        this.stopTimer();
      }
      this.setState({
        secondsRemaining: nextSecondsRemaining,
      });
    }, 100);
  }

  stopTimer() {
    clearInterval(this.timer);
    this.setState({ isOn: false });
  }

  resetTimer() {
    this.setState({ secondsRemaining: this.state.startTime });
  }

  renderResetButton() {
    return (
      <button
        className={'ui large circular fluid icon labeled' + ((this.state.isOn || this.state.startTime === this.state.secondsRemaining) ? ' disabled ' : ' ') + 'button'}
        onClick={this.resetTimer}>
        <i className={'history icon'} />
        Reset
      </button>
    );
  }

  renderResumeButton() {
    if (this.state.secondsRemaining > 0) {
      return (
        <button className={'ui circular large fluid icon labeled button'} onClick={this.startTimer}>
          <i className={'play icon'} />
          Resume
        </button>
      );
    }

    return null;
  }

  // this is rendered when the timer is not running
  renderControlButton() {
    // initial state
    if (this.state.secondsRemaining === this.state.startTime) {
      return (
        <button className={'ui circular large fluid icon labeled button'} onClick={this.startTimer}>
          <i className={'play icon'} />
          Start
        </button>
      );
    }
    if ((this.state.secondsRemaining < this.state.startTime) && (this.state.isOn)) {
      return (
        <button className={'ui circular large fluid icon labeled button'} onClick={this.stopTimer}>
          <i className={'pause icon'} />
          Pause
        </button>
      );
    }

    return (
      <React.Fragment>
        {this.renderResumeButton()}
      </React.Fragment>
    );
  }

  render() {
    let percentComplete = 100 - ((this.state.startTime - this.state.secondsRemaining) / this.state.startTime) * 100;
    return (
      <div className={'ui center aligned grid'}>
        <audio
          key="stopSound"
          ref={x => {
            this.stopSound = x;
          }}
        >
          <source src={hornSound} preload="auto" />
        </audio>
        <div className={'row'}>
          <div className={'middle aligned sixteen wide column'}>
            <div className={'ui huge ' + (this.state.secondsRemaining <! 0 ? 'red' : '') + ' statistic'}>
              <div className={'value'}>
                {this.state.secondsRemaining > 0
                  ? parseFloat(Math.round(this.state.secondsRemaining * 100) / 100).toFixed(1)
                  : 'Stop'}
              </div>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'eight wide center aligned middle aligned column'}>
            {this.renderResetButton()}
          </div>
          <div className={'eight wide center aligned middle aligned column'}>
            {this.renderControlButton()}
          </div>
        </div>
      </div>
    );
  }
}

class Teams extends React.Component {
  constructor(props) {
    super(props);
    let numberOfTeams = 2;
    if (window.localStorage.getItem('numberOfTeams')) {
      numberOfTeams = parseInt(window.localStorage.getItem('numberOfTeams'));
    }
    this.state = {
      numberOfTeams,
    };
  }

  render() {
    const teamColors = ['blue', 'red', 'green', 'yellow'];
    let teams = [];
    for (var i = 0; i < this.state.numberOfTeams; i++) {
      teams.push(this.renderTeam(teamColors[i]));
    }
    return <div className={'ui two column grid'}>{teams}</div>;
  }

  renderTeam(color) {
    return <Team key={color} color={color} />;
  }
}

class Options extends React.Component {
  constructor(props) {
    super(props);
    let currentOption = props.default;
    if (window.localStorage.getItem(props.storageKey)) {
      currentOption = parseInt(window.localStorage.getItem(props.storageKey));
    }
    this.state = {
      currentOption,
      options: props.options,
      storageKey: props.storageKey,
      title: props.title,
    };
  }

  onClick = evt => {
    playSound(tickSound);
    let newOption = evt.target.text;
    console.warn(`updating key ${this.state.storageKey} to ${newOption}`);
    this.setState({ currentOption: parseInt(newOption) });
    window.localStorage.setItem(this.state.storageKey, newOption);
  };

  render() {
    return (
      <React.Fragment>
        <div className={'ui small header'}>{this.state.title}</div>
        <div className={'ui fluid buttons'}>
          {this.state.options.map(i => {
            return (
              <a
                onClick={this.onClick}
                key={i}
                value={i}
                className={'ui ' + (i === this.state.currentOption ? 'active grey' : '') + ' button'}
              >
                {i}
              </a>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helper: false,
      gameplay: false,
      about: false,
    };
  }

  renderGame() {
    let startTime = parseInt(window.localStorage.getItem('timerOption'));
    return (
      <React.Fragment>
        <Timer startTime={startTime} />
        <Teams />
      </React.Fragment>
    );
  }

  renderSettings() {
    return (
      <div className={'ui raised segments'}>
        <div className={'ui segment'}>
          <div className={'ui info icon message'}>
            <i className={'volume up icon'} />
            <div className={'content'}>
              <div className={'header'}>
                Turn up your volume!
              </div>
              <p>
                This website has sound effects &amp; works best when your volume is turned up.
              </p>
            </div>
          </div>
          <Options default={45} options={[30, 45, 60, 90]} storageKey="timerOption" title="Timer (seconds)" />
          <Options default={2} options={[1, 2, 3, 4]} storageKey="numberOfTeams" title="Team Count" />
        </div>
        <div className={'ui segment'}>
          <div
            className={'ui small header'}
            onClick={() => this.setState({ gameplay: !this.state.gameplay })}>
            <span>
              <i className={'caret ' + (this.state.gameplay ? 'down' : 'right') + ' icon'} />
              How to Play
            </span>
          </div>
          {this.state.gameplay && (
          <div>
            <p>
              <b>Requirements:</b> 4+ people. About 5 small slips of paper per person. A few pens. A bowl to hold the paper slips.
            </p>
            <p>
              <b>Setup:</b> Each person writes a clue on a slip of paper. A clue should be a person, place, or thing, and must be
                guessable by anyone in the room. Fold each clue in half and place in the bowl. Divide into 2 teams and pick a
                clue giver to go first.
            </p>
            <p>
              <b>Gameplay:</b> The clue giver takes the bowl, grabs a clue, and tries to have their team guess the clue. A point is scored
                when the clue is guessed. The clue giver can then grab a new clue out of the bowl. The goal is to guess as
                many clues as possible before the timer finishes. When the timer finishes, the clue giver passes the bowl to
                the next team.
            </p>
            <p>
              <b>Rounds:</b> Move on to the next round once all the clues have been guessed. After each round, place the guessed clues back
                into the bowl and begin the next round.
            </p>
            <ul>
              <li>
                <b>1:</b> Clue giver can use as many words as desired (saying "rhymes with" is prohibited)
              </li>
              <li>
                <b>2:</b> Clue giver must act out the clue (no noises!)
              </li>
              <li>
                <b>3:</b> Clue giver can say only one word. One word only!
              </li>
            </ul>                
          </div>
          )}
        </div>
        <div className={'ui segment'}>
          <div
            className={'ui small header'}
            onClick={() => this.setState({ about: !this.state.about })}>
            <span>
              <i className={'caret ' + (this.state.about ? 'down' : 'right') + ' icon'} />
              About This App
            </span>
          </div>
          {this.state.about && (
          <div>
            <p>This app was built by <a href={'http://batterygray.com'} target={'blank'}>Battery Gray</a>, a small team of friends who like building digital products &amp; experiences.</p>
            <p>We build because we enjoy doing so, and appreciate any &amp; all donations if you find this app (or any of our other ones) useful. You can <a href={'https://cash.me/$batterygray'} target={'blank'}>donate here</a>.</p>
            <p>If you have ideas or feedback, please reach out via <a href={'https://docs.google.com/forms/d/e/1FAIpQLSea9BPkpw2DUR4HyVJvdYEm4HwWgXoh-UQao31wEV_4Xo9O2A/viewform?usp=sf_link'} target={'blank'}>this form</a>.</p>
          </div>
          )}
        </div>
        <div className={'ui segment'}>
          <button className={'ui large fluid green button'} onClick={() => this.setState({ helper: !this.state.helper })}>
            <i className={'check icon'} />
            Done
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={'ui ' + (!isMobile() ? 'text' : '') + ' container'}
        style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        {this.state.helper ? this.renderSettings() : this.renderGame()}
        {!this.state.helper && (
          <div className={'ui bottom fixed borderless menu'}>
            <div className={'ui text container'}>
              <div
                className={'item'}
                onClick={() => this.setState({ helper: !this.state.helper })}>
                <i className={'setting icon'} /> Options
              </div>
              <a className={'right item'} href={'https://cash.me/$batterygray'}>
                <i className={'dollar icon'} /> Donate
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
